import { FunctionalComponent, h } from 'preact';
import { useState, useContext } from 'preact/hooks';
import { Link } from 'preact-router/match';
import style from './style.scss';
import { Config } from '../../app';

const link = (text: string, href: string, open: boolean, key?: string): JSX.Element => (
    <Link
        class={open ? style.open : style.close}
        activeClassName={style.active}
        href={href}
        key={key}
    >
        {text}
    </Link>
)

const Header: FunctionalComponent = () => {
    const [open, useOpen] = useState<boolean>(false);
    const dynamicPages = useContext(Config).pages.dynamic

    const links = dynamicPages.map((value, index) => (
        link(value.name, `/${value.url}`, open, `Navbar${index}`)))

    const toggleMenu = (f: (state: boolean) => void): void => {
        console.log("Toggle Clicked")
        f(!open)
    }

    return (
        <header class={[style.header, style.open ? open : ""].join(" ")}>
            <Link
                href={"/"}
            >
                <h1>Ergotherapie</h1>
            </Link>
            <nav >
                <a class={style.icon} onClick={(): void => toggleMenu(useOpen)}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 19 19" width="30px" fill="#000000">
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path d="M4 15h16v-2H4v2zm0 4h16v-2H4v2zm0-8h16V9H4v2zm0-6v2h16V5H4z" />
                    </svg>
                </a>
                {link("Home", "/", open)}
                {links}
            </nav>
        </header >
    );
};

export default Header;
