import { FunctionalComponent, h } from 'preact';
import Markdown from 'preact-markdown';
import { useEffect, useState } from 'preact/hooks';
import { imageParser } from '../../helper/parser';
import { getArticle } from '../../helper/loader';
import { ArticleResponse } from '../../Types';
import Spinner from '../spinner';


import style from './style.scss'

interface Props {
    article: string;
}

const Frame: FunctionalComponent = (props) => (
    <div style={{ display: "flex" }}>
        <div class={[style.row, style.frame].join(" ")} />
        <div class={style.row}>
            {props.children}
        </div>
        <div class={[style.row, style.frame].join(" ")} />
    </div>
)



const Article: FunctionalComponent<Props> = (props: Props) => {
    const [spinnerShow, setSpinnerShow] = useState<boolean>(false)
    const [article, setArticle] = useState<ArticleResponse>({ state: "Fetching" })
    useEffect(() => {
        getArticle(
            props.article,
            setArticle)
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [])
    if (article.state === "Error") {
        return (
            <Frame>
                <p>{article.message}</p>
            </Frame>
        )
    }
    if (article.state === "Fetching") {
        return (
            <Frame>
                <Spinner show={spinnerShow} setShow={setSpinnerShow} />
            </Frame>
        )
    }
    const markdown = imageParser(article.data)
    return (
        <Frame>
            <div class={style.markdown}>
                {Markdown(markdown)}
            </div>
        </Frame>
    )

}

export default Article;