import { h, FunctionComponent } from 'preact';
import { ImageLoader } from '../../helper/loader';
import style from './style.scss';

interface Props {
    imgs: string[];
}

const imageWrapper = (img: string, numImages: number): JSX.Element => (
    <div class={style.column} style={{ maxWidth: `${Math.round(100 / numImages)}%` }}>
        <ImageLoader name={img} class={style.img} />
    </div>
)

const ImageRow: FunctionComponent<Props> = (props: Props) => {
    const row = props.imgs.map((x) => imageWrapper(x, props.imgs.length))
    Math.round(100 / props.imgs.length)
    return (<div>
        <div class={style.container} style={{ paddingBottom: "2vw" }}>
            <div class={style.row} style={{ justifyContent: "center" }}>
                {row}
            </div>
        </div>
    </div>)
}

export default ImageRow;