import { backendURL } from "../..";
import { ConfigState } from "../../Types";

export const getConfig = (setConfigState: (state: ConfigState) => void): void => {
    const url = `${backendURL}config/conifg.json`
    const request: RequestInit = {
        method: "GET",
        headers: {
            'content-type': "application/json"
        }
    }
    fetch(url, request).then(async (response) => {
        const config = await response.json()
        setConfigState({
            state: "LOADED",
            config
        })
    }).catch((reason) => {
        console.log(reason)
        setConfigState({
            state: "FAIL",
            config: reason
        })
    })
}