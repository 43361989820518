import './style/global.scss';
import App from './app';
// import Amplify from '@aws-amplify/core'
// import Auth from '@aws-amplify/auth';
// import API from '@aws-amplify/api'
// import awsconfig from './aws-exports'

export const backendURL = "https://publicwebcontent623984z23.s3.eu-central-1.amazonaws.com/monika/"

// Amplify.configure(awsconfig);
// Auth.configure(awsconfig);
// API.configure(awsconfig)

export default App;
