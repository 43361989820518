import { h, FunctionalComponent } from "preact";
import { Route } from 'preact-router';
import Page from "../../components/page";
import { PageConfig, StaticPage } from "../../Types";

interface Props {
    links: PageConfig[];
    page: PageConfig | StaticPage;
    key: string | number;
}

const createRoute: FunctionalComponent<Props> = (props: Props) => {
    return (
        <Route
            path={props.page.url}
            key={`Route${props.key}`}
            component={
                (): JSX.Element =>
                (
                    <Page page={props.page} key={props.key} />
                )
            }
        />
    )
}

export default createRoute