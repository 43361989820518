import { FunctionComponent, h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.scss';

interface Props {
    relative?: boolean;
}

const Footer: FunctionComponent<Props> = () => {
    return (
        <div class={style.frame} >
            <div class={style.row}>
                <div class={style.button}>
                    <Link class={[style.link, style.customLink].join(" ")} href={"/impressum"}>
                        Impressum
                    </Link>
                </div>
                <div class={style.button}>
                    <Link class={[style.link, style.customLink].join(" ")} href={"/kontakt"}>
                        Kontakt
                    </Link>
                </div>
                <div class={style.button}>
                    <Link class={[style.link, style.customLink].join(" ")} href={"/datenschutzerklärung"}>
                        Datenschutzerklärung
                    </Link>
                </div>
            </div >
        </div >
    )
}

export default Footer;