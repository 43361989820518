import { FunctionalComponent, h, createContext, Context } from 'preact';
import { Route, Router } from 'preact-router';
import { useState, useEffect } from 'preact/hooks';
import Home from './routes/home';
import NotFoundPage from './routes/notfound';

import createRoute from './helper/route';
import { ConfigFile, ConfigState, PageConfig, StaticPage } from './Types';
import Kontakt from './routes/kontakt';
import { getConfig } from './helper/config';

export let Config: Context<ConfigFile>

const App: FunctionalComponent = () => {
    const [configState, setConfigState] = useState<ConfigState>({ state: "FETCHING", config: null })

    useEffect(() => {
        if (configState.state !== "LOADED") {
            getConfig(setConfigState)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (configState.state === "FAIL") {
        return (
            <div>
                <h1>
                    Error
                </h1>
                {configState.config}
            </div>
        )
    }

    if (configState.state === "FETCHING") {
        return (<div />)
    }
    const config = configState.config
    Config = createContext(config)
    const dynamicRoutes = config.pages.dynamic.filter((value) => (value.text !== "")).map((value: PageConfig) => (createRoute({ page: value, links: config.pages.dynamic, key: value.name })))
    const staticRoutes = config.pages.static.map((value: StaticPage) => (createRoute({ page: value, links: config.pages.dynamic, key: value.name })))

    return (
        <div id="preact_root" style={{ height: "100%" }}>
            <Config.Provider value={config}>
                <Router>
                    <Route path="/" component={Home} pages={config.pages.dynamic} home={config.home} />
                    {dynamicRoutes}
                    {staticRoutes}
                    <Route path="/kontakt" component={Kontakt} />
                    <NotFoundPage default />
                </Router>
            </Config.Provider>
        </div>
    );
};

export default App;
