import { FunctionalComponent, h } from "preact";
import { useEffect } from "preact/hooks";
import style from './style.scss';

import Article from "../article";
import Footer from "../footer";
import Header from "../header";
import ImageRow from "../imageRow";
import { PageConfig, StaticPage } from "../../Types";


interface Props {
    page: PageConfig | StaticPage;
}

const Page: FunctionalComponent<Props> = (props: Props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    })
    const isPageConfig = (page: PageConfig | StaticPage): page is PageConfig => {
        return (page as PageConfig).cardImg !== undefined
    }

    let imageRow: JSX.Element = <div />

    if (isPageConfig(props.page)) {
        if (props.page.imgs) {
            imageRow = <ImageRow imgs={props.page.imgs} />
        }
    }

    return (
        <div style={{ height: "100%" }}>
            <Header />
            <div class={style.body}>
                {imageRow}
                <div style={{ display: "flex" }}>
                    <div class={[style.sidebar, style.row].join(" ")} />
                    <div class={[style.row, style.textbox].join(" ")}>
                        <Article article={props.page.text} />
                    </div>
                    <div class={[style.sidebar, style.row].join(" ")} />
                </div>
            </div>
            <Footer relative={true} />
        </div>)
}

export default Page;