import { getImageUrl } from "../loader";

export const imageParser = (article: string): string => {
    const reg = /!(\[\]|\[.*\])\[.*\]/g
    const updateImageTag = (match: string): string => {
        const split = match.split("][");
        return `${split[0]}](${getImageUrl(split[1].replace("]", ""))})`
    }
    const urls: string[] = []
    article.replace(reg, (match: string) => {
        urls.push(updateImageTag(match))
        return match
    })
    return article.replace(reg, (match: string): string => {
        const next = urls.shift()
        return next ? next : match
    })
}