import { FunctionalComponent, h } from "preact";
import { backendURL } from "../..";
import { ArticleResponse } from "../../Types";

interface Props {
    name: string;
    class?: string;
}

export const getImageUrl = (name: string): string => {
    return `${backendURL}images/${name}`
}

export const getArticle = async (
    name: string,
    setArticle: (name: ArticleResponse) => void
): Promise<void> => {
    const url = `${backendURL}article/${name}.md`
    const response = await fetch(url, {
        method: "GET",
        headers: {
            "conten-type": "text/html"
        }
    })
    response.blob().then(async (body: Blob) => {
        const data = await body.text()
        setArticle({ state: "Fetched", data })
    }).catch((error) => {
        setArticle({
            state: "Error", message: error
        })
    })
}

export const ImageLoader: FunctionalComponent<Props> = (props: Props) => {
    return <img src={getImageUrl(props.name)} alt={props.name} class={props.class ? props.class : ""} />
}